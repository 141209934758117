import React from "react";
import Button from "../button/Button";

const HeroSection = () => {
  return (
    <div className="d-flex align-items-center position-relative hero-module">
      <div className="d-flex flex-column  justify-content-center align-items-center hero-section-bg pt-5"></div>

      <div className="container">
        <div className="row  align-items-center justify-content-center">
          <div className="col-12 col-md-9 col-xl-8 text-center">
            <h1 className="display-4 fw-bold " style={{ color: "#003eff" }}>
              Connect
            </h1>
            <span className="fs-1 w-50 text-center">
              Integrate your business deep into global payments systems
            </span>
            <div className="mt-4">
              <Button children={"Sign up"} className="px-4 py-2 fs-5" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
