import React, { useContext } from "react";
import DocumentationCard from "../documentationCard/DocumentationCard";
import programApiImg from "../../assets/images/program.png";
import eventsApiImg from "../../assets/images/events.png";
import authApiImg from "../../assets/images/auth.png";
import disputesApiImg from "../../assets/images/disputes.png";
import externalApiImg from "../../assets/images/external.png";
import clearBank1 from "../../assets/images/clearbank1.png";
import clearBank2 from "../../assets/images/clearbank2.png";
import ApiSection from "./ApiSection";
import CardContext from "../../context/CardContext";
import internationalRemittance1 from "../../assets/images/internationalremittances1.jpg";
import kycVerification1 from "../../assets/images/kyccerification1.png";
import kycVerification2 from "../../assets/images/kyccerification2.png";
import modular1 from "../../assets/images/modular1.png";
import modular2 from "../../assets/images/modular2.png";
import modular3 from "../../assets/images/modular3.png";
import modular4 from "../../assets/images/modular4.png";
import modular5 from "../../assets/images/modular5.png";
import paymentSchemes1 from "../../assets/images/paymentschemes1.jpg";
import paymentSchemes2 from "../../assets/images/paymentschemes2.png";
import paymentSchemes3 from "../../assets/images/paymentschemes3.jpg";
import payswix1 from "../../assets/images/payswix1.jpg";
import payswix2 from "../../assets/images/payswix2.jpg";
import payswix3 from "../../assets/images/payswix3.jpg";
import payswix4 from "../../assets/images/payswix4.jpg";
import remitOne1 from "../../assets/images/remitone1.png";
import remitOne2 from "../../assets/images/remitone2.png";
import remitOne3 from "../../assets/images/remitone3.png";
import remitOne4 from "../../assets/images/remitone4.png";
import remitOne5 from "../../assets/images/remitone5.png";
import remitOne6 from "../../assets/images/remitone6.png";
import remitOne7 from "../../assets/images/remitone7.png";
import remitOne8 from "../../assets/images/remitone8.png";

// New Images
import clearBankLogo from "../../assets/images/clearbank.png";
import internationalRemittanceLogo from "../../assets/images/internationremittance.png";
import kycVerificationLogo from "../../assets/images/kycverification.png";
import modularLogo from "../../assets/images/modular.png";
import paymentSchemesLogo from "../../assets/images/paymentschemes.png";
import payswixLogo from "../../assets/images/payswix.png";
import remitOneLogo from "../../assets/images/remitone.png";

const DeveloperDocSection = () => {
  const cardData = [
    {
      cardImg: clearBankLogo,
      cardName: "Clear Bank",
      text: "Add, modify and request customer account data.",
      link: "/",
      subCards: [
        {
          cardName: "ClearBank-Account",
          cardIcon: clearBankLogo,
          link: "https://apim.fts.money/devportal/apis/52f143b3-dd2d-43ca-998a-f27c5c1886d8/documents",
        },
        {
          cardName: "ClearBank-Faster-Payment",
          cardIcon: clearBankLogo,
          link: "https://apim.fts.money/devportal/apis/52f143b3-dd2d-43ca-998a-f27c5c1886d8/documents",
        },
      ],
    },
    {
      cardImg: internationalRemittanceLogo,
      cardName: "International Remittances",
      text: "Receive alerts that an action was taken on a customer's account.",
      link: "/",
      subCards: [
        {
          cardName: "Wise-Get-Currency-Quote",
          cardIcon: internationalRemittanceLogo,
          link: "https://apim.fts.money/devportal/apis/edc1c668-ce67-4497-b8c4-2a335455db96/documents/default",
        },
      ],
    },
    {
      cardImg: kycVerificationLogo,
      cardName: "KYC-Verification",
      text: "Actively participate in your customers' transaction approvals and denials.",
      link: "/",
      subCards: [
        {
          cardName: "GBG-Idscan",
          cardIcon: kycVerificationLogo,
          link: "https://apim.fts.money/devportal/apis/155cfa24-6d63-475c-8994-e5ffa88797a4/documents/default",
        },
        {
          cardName: "Veriff-ID",
          cardIcon: kycVerificationLogo,
          link: "https://apim.fts.money/devportal/apis/155cfa24-6d63-475c-8994-e5ffa88797a4/documents/default",
        },
      ],
    },
    {
      cardImg: modularLogo,
      cardName: "Modular",
      text: "Actively participate in your customers' transaction approvals and denials.",
      link: "/",
      subCards: [
        {
          cardName: "Modular-Account",
          cardIcon: modularLogo,
          link: "https://apim.fts.money/devportal/apis/6ce7bc5c-1cba-47e0-8d35-495587106be8/documents/default",
        },
        {
          cardName: "Modular-Beneficiary",
          cardIcon: modularLogo,
          link: "https://apim.fts.money/devportal/apis/6ce7bc5c-1cba-47e0-8d35-495587106be8/documents/default",
        },
        {
          cardName: "Modular-Cards",
          cardIcon: modularLogo,
          link: "https://apim.fts.money/devportal/apis/6ce7bc5c-1cba-47e0-8d35-495587106be8/documents/default",
        },
        {
          cardName: "Modular-Customer",
          cardIcon: modularLogo,
          link: "https://apim.fts.money/devportal/apis/6ce7bc5c-1cba-47e0-8d35-495587106be8/documents/default",
        },
        {
          cardName: "Modular-Transaction",
          cardIcon: modularLogo,
          link: "https://apim.fts.money/devportal/apis/6ce7bc5c-1cba-47e0-8d35-495587106be8/documents/default",
        },
      ],
    },
    {
      cardImg: paymentSchemesLogo,
      cardName: "Payment Schemes",
      text: "Integrate the dispute process into your app or website to provide a seamless dispute",
      link: "/",
      subCards: [
        {
          cardName: "Checkout-Card-Acquirer",
          cardIcon: paymentSchemesLogo,
          link: "https://apim.fts.money/devportal/apis/2932a603-29ca-4138-a750-2d7b6e48f1b5/documents/default",
        },
        {
          cardName: "World_Pay",
          cardIcon: paymentSchemesLogo,
          link: "https://apim.fts.money/devportal/apis/2932a603-29ca-4138-a750-2d7b6e48f1b5/documents/default",
        },
        {
          cardName: "Yapily-Open-Banking-Providers",
          cardIcon: paymentSchemesLogo,
          link: "https://apim.fts.money/devportal/apis/2932a603-29ca-4138-a750-2d7b6e48f1b5/documents/default",
        },
      ],
    },
    {
      cardImg: payswixLogo,
      cardName: "Payswix",
      text: "Participate in the decisioning for approving and denying bill payments and ACH debits.",
      link: "/",
      subCards: [
        {
          cardName: "Payswix-Account",
          cardIcon: payswixLogo,
          link: "https://apim.fts.money/devportal/apis/a7382204-1f32-4638-9e89-3f6a4de7ab92/documents/default",
        },
        {
          cardName: "Payswix-Login",
          cardIcon: payswixLogo,
          link: "https://apim.fts.money/devportal/apis/a7382204-1f32-4638-9e89-3f6a4de7ab92/documents/default",
        },
        {
          cardName: "Payswix-Rate-Conversion",
          cardIcon: payswixLogo,
          link: "https://apim.fts.money/devportal/apis/a7382204-1f32-4638-9e89-3f6a4de7ab92/documents/default",
        },
        {
          cardName: "Payswix-Transaction",
          cardIcon: payswixLogo,
          link: "https://apim.fts.money/devportal/apis/a7382204-1f32-4638-9e89-3f6a4de7ab92/documents/default",
        },
      ],
    },
    {
      cardImg: remitOneLogo,
      cardName: "RemitOne",
      text: "Participate in the decisioning for approving and denying bill payments and ACH debits.",
      link: "/",
      subCards: [
        {
          cardName: "Collection-Points",
          cardIcon: remitOneLogo,
          link: "https://apim.fts.money/devportal/apis/e0f3926d-64f9-42e2-b0e5-61e49401f9f7/documents/default",
        },
        {
          cardName: "Destination_Countrier",
          cardIcon: remitOneLogo,
          link: "https://apim.fts.money/devportal/apis/e0f3926d-64f9-42e2-b0e5-61e49401f9f7/documents/default",
        },
        {
          cardName: "Rates",
          cardIcon: remitOneLogo,
          link: "https://apim.fts.money/devportal/apis/e0f3926d-64f9-42e2-b0e5-61e49401f9f7/documents/default",
        },
        {
          cardName: "Remitone-oBenificiary",
          cardIcon: remitOneLogo,
          link: "https://apim.fts.money/devportal/apis/e0f3926d-64f9-42e2-b0e5-61e49401f9f7/documents/default",
        },
        {
          cardName: "Remitone-Mobile-Operators",
          cardIcon: remitOneLogo,
          link: "https://apim.fts.money/devportal/apis/e0f3926d-64f9-42e2-b0e5-61e49401f9f7/documents/default",
        },
        {
          cardName: "Remitone-Remitter",
          cardIcon: remitOneLogo,
          link: "https://apim.fts.money/devportal/apis/e0f3926d-64f9-42e2-b0e5-61e49401f9f7/documents/default",
        },
        {
          cardName: "Remitone-Transaction",
          cardIcon: remitOneLogo,
          link: "https://apim.fts.money/devportal/apis/e0f3926d-64f9-42e2-b0e5-61e49401f9f7/documents/default",
        },
        {
          cardName: "Utility-Companies",
          cardIcon: remitOneLogo,
          link: "https://apim.fts.money/devportal/apis/e0f3926d-64f9-42e2-b0e5-61e49401f9f7/documents/default",
        },
      ],
    },
  ];

  const { cardInfo, setCardInfo } = useContext(CardContext);

  const onClick = (cardName, subcards, key) => {
    if (cardInfo.showSubCards) {
      localStorage.setItem(
        "cardInfo",
        JSON.stringify({ showSubCards: false, key: "" })
      );
      setCardInfo(JSON.stringify({ showSubCards: false, key: "" }));
    } else {
      localStorage.setItem(
        "cardInfo",
        JSON.stringify({
          showSubCards: true,
          key: key,
        })
      );

      setCardInfo(JSON.stringify({ showSubCards: true, key: key }));
    }
  };

  return (
    <>
      <div className="container mt-5">
        {cardInfo.showSubCards ? (
          <ApiSection
            subcards={cardData[cardInfo.key].subCards}
            name={cardData[cardInfo.key].cardName}
            onClick={onClick}
          />
        ) : (
          <div className="row  gap-5  ">
            <span className="display-3 text-center">
              Explore our developer docs.
            </span>
            <div className="d-flex gap-3 align-items-center justify-content-center  flex-wrap">
              {cardData.map(
                ({ cardImg, cardName, text, link, subCards }, key) => (
                  <div key={key}>
                    <DocumentationCard
                      cardImg={cardImg}
                      num={key}
                      cardName={cardName}
                      text={text}
                      link={link}
                      subCards={subCards}
                      onClcik={onClick}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DeveloperDocSection;
