import React from "react";

const InputField = ({ placeholder="",className="" }) => {
  return (
    <>
      <input
        className={`border-0 outline-none w-100 rounded-4 ${className}`}
        type="text"
        placeholder={placeholder}
      />
    </>
  );
};

export default InputField;
