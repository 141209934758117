import React from "react";
import ftsLogo from "../../assets/images/Logo.svg";
import "../../styles/navbar.css";
import DropDown from "../dropdown/DropDown";
import Button from "../button/Button";

const Navbar = () => {
  const navLinks = [
    {
      name: "About",
      link: "/",
    },
    {
      name: "Contact",
      link: "/",
    },
    {
      name: "News & insights",
      link: "/",
    },
    {
      name: "Sign in",
      link: "/",
    },
  ];

  const dropDownList = [
    {
      name: "Community",
      options: [],
    },
    {
      name: "Connect",
      options: [],
    },
    {
      name: "Fintech Store",
      options: [],
    },
    {
      name: "Fintech Factory",
      options: [],
    },
  ];
  return (
    <>
      <nav className="navbar navbar-bg ps-1">
        <div className="container-fluid">
          <div className="row w-100">
            <div className="logo py-5 col-2">
              <img src={ftsLogo} alt="" />
            </div>

            <div className="col-10 py-3">
              <div className="d-flex flex-column gap-3">
                <div className="d-flex align-items-center justify-content-end gap-3 px-3">
                  {navLinks.map(({ link, name }, key) => (
                    <div key={key} className="d-flex align-items-center gap-3">
                      <a className="navLink" href={link}>
                        {name}
                      </a>
                      {!(navLinks.length === key + 1) && (
                        <span style={{ color: "#636363", fontSize: "13px" }}>
                          |
                        </span>
                      )}
                    </div>
                  ))}
                </div>

                <div className="d-flex align-items-center justify-content-end gap-3 ">
                  {dropDownList.map(({ name }, key) => (
                    <div key={key}>
                      <DropDown name={name} className={"ps-3"} />
                    </div>
                  ))}

                  <Button
                    children={"Sign up"}
                    className="ms-2 px-4 py-2 fs-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
