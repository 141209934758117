import { Route, Routes } from "react-router-dom";
import ApiHubPage from "../components/apihub/ApiHubPage";

const Router = () => {
  return (
    <Routes>
      <Route>
        <Route path="/" element={<ApiHubPage />} />
      </Route>
    </Routes>
  );
};

export default Router;
