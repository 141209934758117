import React from "react";

const Button = ({ children, className = "", onclick }) => {
  return (
    <button
      onClick={onclick}
      type="submit"
      className={`rounded-5  border-0  text-white text-center ${className}`}
      style={{
        background: "linear-gradient(270deg, #54F0E4 -9.4%, #003EFF 150.32%)",
      }}
    >
      {children}
    </button>
  );
};

export default Button;