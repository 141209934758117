import { useState } from "react";
import CardContext from "./CardContext";

const CardContextProvider = ({ children }) => {
  const value = JSON.parse(localStorage.getItem("cardInfo"));
  const [cardInfo, setCardInfo] = useState(value ? value : "");

  return (
    <CardContext.Provider value={{ cardInfo, setCardInfo }}>
      {children}
    </CardContext.Provider>
  );
};

export default CardContextProvider;
