import ApiSubCards from "../documentationCard/ApiSubCards";
import backIcon from "../../assets/images/back.png";

const ApiSection = ({ subcards = [], onClick, name }) => {
  return (
    <>
      <div className="row gap-5">
        <div className="d-flex justify-content-center justify-content-start gap-5 align-items-center ">
          <img
            src={backIcon}
            width={24}
            alt=""
            onClick={onClick}
            className="cursor-pointer"
          />
          <span className="display-5 ">{name}</span>
        </div>
        <div className="d-flex gap-3 align-items-center justify-content-center justify-content-lg-start px-lg-5 px-0 mx-lg-5     flex-wrap">
          {subcards.map(({ cardName, cardIcon, link }, key) => (
            <div key={key}>
              <ApiSubCards
                cardName={cardName}
                link={link}
                cardIcon={cardIcon}
                num={key}
                mainCardName={name}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ApiSection;
