import React from "react";
import dropDownIcon from "../../assets/images/downIcon.svg";

const DropDown = ({ className, name }) => {
  return (
    <>
      <div className={`d-flex gap-2 align-items-center ${className}`}>
        <span
          className="fw-bold p-1"
          style={{ fontSize: "18px", letterSpacing: "1px" }}
        >
          {name}
        </span>
        <img src={dropDownIcon} alt="" />
      </div>
    </>
  );
};

export default DropDown;
