import Navbar from "../navbar/Navbar";
import HeroSection from "./HeroSection";
import DeveloperDocSection from "./DeveloperDocSection";
import Footer from "../footer/Footer";
import CardContextProvider from "../../context/CardContextProvider";

const ApiHubPage = () => {
  return (
    <>
      <div className="container-fluid p-0">
        <Navbar />
        <HeroSection />
        <CardContextProvider>
          <DeveloperDocSection />
        </CardContextProvider>

        <Footer />
      </div>
    </>
  );
};

export default ApiHubPage;
