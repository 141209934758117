import React from "react";
import "../../styles/footer.css";
// import waveGif from "../../assets/images/logo-wave.gif";
import wave from "../../assets/video/wave.mp4";
import InputField from "../inputfield/InputField";
import XIcon from "../../assets/images/Xicon.svg";
import linkedinIcon from "../../assets/images/linkedinIcon.svg";
import copyrightLogo1 from "../../assets/images/pcilogo.jpg";
import copyrightLogo2 from "../../assets/images/siteseallogo.gif";

const Footer = () => {
  const sitemapList = [
    {
      mainName: "Community",
      mainlink: "/",
      list: [
        {
          name: "Money transfer businesses",
          link: "/",
        },
        {
          name: "PSP (Payment Service Providers)",
          link: "/",
        },
        {
          name: "Big merchants",
          link: "/",
        },
        {
          name: "Licensed digital currency exchanges",
          link: "/",
        },
        {
          name: "SMEs",
          link: "/",
        },
        {
          name: "Digital marketplaces",
          link: "/",
        },
        {
          name: "Gig economy platforms",
          link: "/",
        },
        {
          name: "Native digital brands",
          link: "/",
        },
        {
          name: "Retailers",
          link: "/",
        },
        {
          name: "Developers & fintech innovators",
          link: "/",
        },
        {
          name: "Networking opportunities",
          link: "/",
        },
        {
          name: "Knowledge sharing",
          link: "/",
        },
      ],
    },

    {
      mainName: "Connect",
      mainlink: "/",
      list: [
        {
          name: "Payment orchestration",
          link: "/",
        },
        {
          name: "White label infrastructure",
          link: "/",
        },
        {
          name: "Digital currency payment gateway",
          link: "/",
        },
        {
          name: "API Hub",
          link: "/",
        },
        {
          name: "Sandbox",
          link: "/",
        },
        {
          name: "Documentation",
          link: "/",
        },
      ],
    },

    {
      mainName: "Fintech Store",
      mainlink: "/",
      list: [
        {
          name: "Digital Onboarding",
          link: "/",
        },
        {
          name: "eVouchers",
          link: "/",
        },
        {
          name: <strong className="pt-5">Fintech Factory</strong>,
          link: "/",
        },
        {
          name: "Customisation",
          link: "/",
        },
        {
          name: "SDK",
          link: "/",
        },
        {
          name: <strong className="pt-5">About</strong>,
          link: "/",
        },
        {
          name: "Platform & infrastructure",
          link: "/",
        },
        {
          name: "Security & compliance",
          link: "/",
        },
        {
          name: "Platform features",
          link: "/",
        },
        {
          name: "Partnerships",
          link: "/",
        },
      ],
    },

    {
      mainName: "Coming soon",
      mainlink: "/",
      list: [
        {
          name: "Money transfers",
          link: "/",
        },
        {
          name: "Virtual IBAN & settlement",
          link: "/",
        },
        {
          name: <strong className="pt-5">Legal</strong>,
          link: "/",
        },
      ],
    },
  ];
  return (
    <>
      <div className="container-fluid p-0">
        <div className="w-100 wave-div">
          {/* <img src={waveGif} alt="" className="w-100 wave" /> */}
          <video
            className="w-100 wave"
            autoPlay
            playsInline
            muted
            loop
            preload="auto"
          >
            <source src={wave} type="video/mp4"></source>
          </video>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 1440 108.7"
            style={{ enableBackground: "new 0 0 1440 108.7" }}
            xmlSpace="preserve"
          >
            <path
              fill="#000044"
              d="M0,26.3C66.5,17.5,206.3,0.2,432.9,0.7c155,0.4,284.6,13.6,401.1,25.4c96.4,9.8,184,18.7,269.7,18.7
	c183,0,275.2-15,315.1-21.4c10.3-1.7,17.2-2.8,21.2-2.9v26.2v38.6v23.3H0V85.3V46.7V26.3z"
            ></path>
          </svg>
        </div>

        <footer className="footer">
          <div className="subscrition-div">
            <div className="container">
              <div className="row justify-content-center align-items-center flex-column">
                <div className="col-md-7 pb-4 mb-4 text-center">
                  <span
                    className=""
                    style={{ fontSize: "32px", color: "#54F0E4" }}
                  >
                    Get news about the fast evolving global payments space
                  </span>
                </div>
                <div className="col-md-5">
                  <form className="d-flex flex-column align-items-center justify-content-center gap-5">
                    <InputField
                      placeholder={"Email"}
                      className="subscription-field"
                    />

                    <button type="submit" className="subscription-btn">
                      Subscribe
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="sitemap-div">
            <div className="container">
              <div className="row ">
                <div className="col-md-3">
                  <div className="d-flex flex-column justify-content-between ">
                    <div
                      className="d-flex flex-column gap-4"
                      style={{ color: "#54f0e4" }}
                    >
                      <div className="title fs-1">
                        Reimagining global payments
                      </div>
                      <div className="fs-4 description">
                        The FTS.Money platform connects you to smarter, simpler,
                        safer global payments. By reimagining how money flows
                        worldwide, you get more ways to grow.
                      </div>
                    </div>

                    <div className="d-flex gap-4 align-items-center mt-5">
                      <a href="/">
                        <img src={XIcon} alt="" />
                      </a>
                      <a href="/">
                        <img src={linkedinIcon} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-9">
                  <div className="row gy-4 gap-md-0 gap-3">
                    {sitemapList.map(({ mainName, mainlink, list }, key) => (
                      <div key={key} className="col-md-3 ps-4">
                        <ul className="sitemap-list">
                          <li>
                            <a href={mainlink}>
                              <strong>{mainName}</strong>
                            </a>
                          </li>
                          {list.map(({ name, link }, key) => (
                            <li key={key}>
                              <a href={link}>{name}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="copyright-div">
            <div className="container  ">
              <div className="d-flex gap-3 justify-content-md-end justify-content-center mb-4">
                <div>
                  <img src={copyrightLogo1} alt="" width={55} height={32} />
                </div>
                <div>
                  <img src={copyrightLogo2} alt="" width={130} height={32} />
                </div>
              </div>

              <div className="row">
                <div
                  className="col-md-6 justify-content-start  pb-4 pb-md-0 text-md-start text-center"
                  style={{ color: "#ffff" }}
                >
                  * Throughout this website “countries” refers to jurisdictions
                  with international borders and distinct currencies and is not
                  intended to make a political statement.
                </div>
                <div
                  className="col-md-6 justify-content-end text-md-end text-center"
                  style={{ color: "#636363" }}
                >
                  <div>©2024 FTS.Money Limited. All Rights Reserved.</div>
                  <div>
                    Website by{" "}
                    <a
                      href="/"
                      style={{ color: "#636363" }}
                      className="text-decoration-none"
                      target="_blank"
                    >
                      Stepworks
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
