import { Link } from "react-router-dom";

const ApiSubCards = ({ cardIcon, cardName, link, num, mainCardName }) => {
  return (
    <>
      <Link to={link} className="text-decoration-none" target="_blank">
        <div
          key={num}
          className="d-flex align-items-center justify-content-start gap-5 px-5 p-3 doc-card apisubcard rounded-4 cursor-pointer"
        >
          <img
            src={cardIcon}
            alt=""
            width={150}
            className={`rounded-3 ${
              mainCardName === "Modular" && "bg-black p-3"
            }`}
          />
          <span className="fs-6 text-center " style={{ color: "#020626" }}>
            {cardName}
          </span>
        </div>
      </Link>
    </>
  );
};

export default ApiSubCards;
