import React from "react";
import leftArrow from "../../assets/images/leftArrow.svg";

const DocumentationCard = ({
  cardImg,
  cardName,
  text,
  link,
  onClcik,
  num,
  subCards,
}) => {
  return (
    <>
      <div
        key={num}
        className="d-flex flex-column gap-3 doc-card rounded-4 "
        style={{ width: "23rem", height: "18rem" }}
      >
        <div className="pt-3 d-flex align-items-center justify-content-center">
          <img
            src={cardImg}
            alt=""
            width={300}
            height={100}
            className={`rounded-3 ${cardName === "Modular" && "bg-black p-3"}`}
          />
        </div>

        <div className="d-flex flex-column justify-content-between h-100 pt-2 pb-3 px-3 ">
          <div className="d-flex flex-column ">
            <span className="fs-5 " style={{ color: "#020626" }}>
              {cardName}
            </span>
            <span style={{ fontSize: "12px", color: "#4D5499" }}>{text}</span>
          </div>

          <a
            href={link}
            className="d-flex gap-2 align-items-center text-decoration-none text-black "
            style={{ width: "max-content" }}
            onClick={() => onClcik(cardName, subCards, num)}
          >
            <span className="">Api List</span>
            <img className="align-self-center" src={leftArrow} alt="" />
          </a>
        </div>
      </div>
    </>
  );
};

export default DocumentationCard;
